<template>
  <div class="how-it-works">
    <div class="max-width">
      <span class="d-block font-27 greytext--text">{{ $t('howItWorks.howItWorks') }}</span>
      <span class="d-block font-48 darktext--text">{{$t('howItWorks.medicalProfessionals')}}</span>

      <v-row>
        <!-- step 1 -->
        <v-col cols="12" md="6" class="px-5">
          <step-section :isWhite="false" :data="steps[0]"></step-section>
        </v-col>
        <!-- step 2 -->
        <v-col style="margin-top: 70px" cols="12" md="6" class="px-5">
          <step-section :isWhite="false" :data="steps[1]"></step-section>
        </v-col>
        <!-- step 3 -->
        <v-col
          style="top: -70px; position: relative"
          cols="12"
          md="6"
          class="px-5"
        >
          <step-section :isWhite="false" :data="steps[2]"></step-section>
        </v-col>
        <!-- step 4 -->
        <v-col style="margin-top: 50px" cols="12" md="6" class="px-5">
          <step-section :isWhite="false" :data="steps[3]"></step-section>
        </v-col>
      </v-row>

      <recommendations></recommendations>
      <doctor-sign-up-card class="mb-5">
      </doctor-sign-up-card>
    </div>
  </div>
</template>

<script>
import Recommendations from "../../home/components/Recommendations.vue";
import StepSection from "../components/Step";
import DoctorSignUpCard from "../../../components/core/DoctorSignUpCard.vue";
export default {
  components: {
    StepSection,
    Recommendations,
    DoctorSignUpCard,
  },
  data: (vm) => ({
    steps: [
      {
        title: vm.$t("howItWorks.firstStep"),
        desc: vm.$t("howItWorks.professionals.firstStepSentense"),
        img: require("@/assets/images/how-it-works/step1-pro.png"),
        sideImg: "",
        side: "",
      },
      {
        title: vm.$t("howItWorks.secondStep"),
        desc: vm.$t("howItWorks.professionals.secondStepSentense"),
        img: require("@/assets/images/how-it-works/step2-pro.png"),
        sideImg: require("@/assets/images/how-it-works/right.png"),
        side: "right",
      },
      {
        title: vm.$t("howItWorks.thirdStep"),
        desc: vm.$t("howItWorks.professionals.thirdStepSentense"),
        img: require("@/assets/images/how-it-works/step3-pro.png"),
        sideImg: require("@/assets/images/how-it-works/left.png"),
        side: "left",
      },
      {
        title: vm.$t("howItWorks.fourthStep"),
        desc: vm.$t("howItWorks.professionals.fourthStepSentense"),
        img: require("@/assets/images/how-it-works/step4-pro.png"),
        sideImg: require("@/assets/images/how-it-works/right.png"),
        side: "right",
      },
    ],
  }),
};
</script>

<style lang="scss">
.how-it-works {
  &__hero-section {
    background: #ffffff;
    backdrop-filter: blur(64.5px);
    height: auto !important;
    border-radius: 30px;
    margin-bottom: 250px;
    box-shadow: 0px 56px 80px 0px #adc7ee66 !important;
  }
}
</style>
